.projects-container{
    height: 100%;
    width: 100%;
    padding-bottom: 1rem;
    /* margin: 0;
    padding-left: 0;
    padding-right: 0; */
}
.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
  }
  
  .profile-card {
    margin-top: 2rem;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 2rem 1rem;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
  }
  
  .profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .profile-info-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .profile-label {
    font-weight: bold;
    color: #444;
    margin-right: 3rem;
  }
  
  .profile-value {
    color: #777;
  }
  @media (max-width:550px) {
    .profile-card{
      flex-direction: column;
    }
  }