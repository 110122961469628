.login {
    background-color: #f5f5f5;
    padding: 3rem 0;
    min-height: 100vh;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-self: center;

  }
  
  .containerLogin {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 10px;
  box-shadow: 3px 3px 3px 3px black ;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
  }
  
  .login-title {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .containerLogin input {
    width: 100%;
    min-width: 300px;
    padding: 0.5rem;
  border: none;
  border-radius: 4px;
  font-size: .9rem;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
  }
  
 /* .login button {
    background-color: #e12d4e;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
  } */
  
  .login form button:hover {
    color: #fff;
    background-color: #052252;
  }
  .google-btn {
    margin-top: 2rem;
    border: 2px solid #4285f4;
    color: #fff;
    background-color: #131415;

    font-size: 16px;
    font-weight: bold;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .google-btn:hover {
    background-color: #fff;
    color: #4285f4;


  }
  
  