.cta {
    /* background-color: #0e2037; */
    background-color: #080808;

    color: #ffffff;
    text-align: center;
    padding: 2rem 0;
  }
  
  .cta-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  
  .cta-btn {
    background-color: #4285f4;
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .cta-btn:hover {
    background-color: #357ae8;
  }
  