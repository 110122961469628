*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* color: black; */
    /* font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    font-family: Arial, Helvetica, sans-serif;
}
/* .gradient{
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(121,111,9,1) 0%, rgba(203,222,94,1) 0%, rgba(97,103,222,1) 0%, rgba(191,209,108,1) 100%, rgba(94,222,103,1) 100%);} */

.gradient{
    /* background: #acb6e5;   */
    /* background: -webkit-linear-gradient(to right, #86fde8, #acb6e5);   */
    /* background: linear-gradient(to right, #86fde8, #acb6e5);  */
    
    /* background-color:#7ca0cd; */
    /* background-color:#26446b; */
    /* background-color: #4285f4; */
    background-image: linear-gradient(135deg, #1e5799, #207cca, #7db9e8);


        

    }
