.footer {
    
    /* background-color: #0e2037; */
    background-color: #080808;
    padding: 2rem 0;
    color: #d4dbe0;
    font-family: Arial, sans-serif;
    box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1);
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .footer-column {
    width: calc(33.33% - 1rem);
}
  
  .footer-heading {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: white;
  }
  
  
  .footer-links {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 0.5rem;
  }
  
  .footer-links a {
    color: #d4dbe0;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    margin-top: 2rem;
    text-align: center;
    font-size: 0.9rem;
  }
  .footer-bottom p{
    color: rgb(187, 187, 192);

  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-wrap: wrap;
      /* flex-direction: column; */
      align-items: center;
      /* justify-content: center; */
    }
  
    .footer-column {
      width: 100%;
      margin-bottom: 2rem;
      text-align: center;  

    }
  }
  @media (max-width: 550px) {
    
    .footer-container {
      flex-wrap: wrap;
    }
  
    .footer-column {
      width: 100%;
      margin-bottom: 2rem;
      text-align: center;  

    }
  }
  