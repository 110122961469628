.how-it-works {
    text-align: center;
    padding: 2rem 0;
  }
  
  .steps-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 4rem;
  }
  
  .step {
    width: 22rem;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 1.5rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .step p {
    font-size: 1rem;
    color: #0e2037;
  }

  .step-icon {
    font-size: 2rem;
    font-weight: bold;
    background-color: #0e2037;
    color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    margin: 0 auto 1rem;
  }
  
  .step h3 {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .step {
      width: 100%;
    }
  }
  