.features {
  text-align: center;
  padding: 2rem 0;
  background-color: #050505;
}

.features-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.feature {
  width: 30%;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.feature-icon-container {
  background-color: #0e2037;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.feature-icon {
  font-size: 2rem;
  color: #fff;
}

.feature h3 {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .feature {
    width: 100%;
  }
}
