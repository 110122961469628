.testimonials {
    text-align: center;
    padding: 2rem 0;
    background-color: #3983de;
  }
  
  .testimonials-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
  
  .testimonial {
    width: 40%;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  
  .testimonial img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 1rem;
  }
  
  .testimonial h3 {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .testimonial {
      width: 100%;
    }
  }
  