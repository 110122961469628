

.aboutUs {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;
    background-image: linear-gradient(135deg, #1e5799, #207cca, #7db9e8);
    min-height: 100vh;
    padding: 0 2rem;
  }
  
  .aboutContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    padding: 3rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    margin-top: 1rem;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #207cca;
    margin-bottom: 1.5rem;
  }
  
  .aboutContent {
    max-width: 70%;
    text-align: justify;
  }
  
  .aboutContent p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #34495e;
  }
  @media (max-width:550px) {
    .aboutUs{
        padding: 0;
    }
    .aboutContent{
        width: 90%;
        max-width: 100%;
    }
    .aboutContainer{
        padding: .5rem;
        width: 90%;
    }
  }

  