.signup {
  padding: 3rem 0;
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.containerSignup {
  max-width: 800px;
  width: 500px;
  margin: 0 auto;
  /* border: 1px solid rgb(235, 232, 238); */
  box-shadow: 5px 5px 5px 5px black ;
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-title {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.containerSignup input {
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  font-size: .9rem;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}

.buttonStyle {
  background-color: #114cab;
    color: #fff;  
  border: 2px solid #4285f4;
  border: none;
  margin: auto;
  padding: .6rem 2rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
    font-weight: bold;
}

.signup button:hover {
  background-color: #052252;
  color: #fff;
} 

@media (max-width:650px) {
  .containerSignup{
    box-shadow: 3px 3px 3px 3px black ;
    max-width: 98vw;
    padding: 2rem 1rem;
  }
}
