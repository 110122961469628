.project-form-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    margin-top: 50px;
    border-radius: 10px;
    padding: 2rem 1rem;
color: rgb(12, 12, 12);
    /* border: 1px solid #ccc; */
    /* width: max-content; */
    max-width: 800px;
    
    margin: 1rem auto;
    background-color: #ffffff;
    min-width: 45%;
    
    /* box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2); */
    
  }
  .ps{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .map-button{
    background-color:#2479d4;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    font-weight: 100;
  }
  .map-button:hover{
    background-color: #060606;
  }
  .project-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 400px;
  }
  .google-map-container {
    height: 400px; /* set the height of the map container */
    width: 100%; /* set the width of the map container */
    margin-bottom: 20px; /* add some bottom margin */
    border: 1px solid #ccc; /* add a border */
    border-radius: 5px; /* add some border radius */
  }
  
  
  .form-group {
    margin-bottom: 20px;
  }
  select {
    width: 100%;
    /* padding: 0.5rem; */
    margin-bottom: 1rem;
    border: 1px solid #0e2037;
    border-radius: 4px;
    height: 30px;
    font-size: medium;
  }
  
  label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  input,
  textarea {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    resize: none;
  }
  
  .submit-button {
    width: 100%;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-block;

  }
  
  .submit-button:hover {
    background-color: #0062cc;
  }
  
  /* @media (max-width:450px) {
    .project-form-container{
      width: auto;
      padding: 0;
      border-radius: 0;
      padding: 1rem 0;
    }
    form{
      width: 90%;
    }
    
  }
  @media (max-width:550px) {
    .project-form-container{
      /* width: auto; */
      /* padding: 0;
      border-radius: 0;
      padding: 1rem 0;
      width: 100%;
    }
    
  /* } */ 
  @media (max-width:700px) {
    .project-form-container{
      min-width: 100vw;
    }
  }
  @media (max-width:550px) {
    .project-form-container{
      margin: 0 auto;
      border-radius: 0;
      min-width: 100vw;
    }
  }