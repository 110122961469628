.privacy-policy-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .privacy-policy-container h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .privacy-policy-container h2 {
    font-size: 1.5rem;
    margin: 1.5rem 0;
  }
  
  .privacy-policy-container p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .privacy-policy-container ul {
    padding-left: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .privacy-policy-container li {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  