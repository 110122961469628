.pricing {
    text-align: center;
    padding: 2rem 0;
    background-color: #ffffff;
  }
  
  .pricing-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
  
  .pricing-card {
    width: 40%;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #d4dbe0;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .pricing-card h3 {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .price {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .price span {
    font-size: 1rem;
  }
  
  .pricing-card ul {
    text-align: left;
    padding: 0;
    list-style: none;
  }
  
  .pricing-card li {
    margin-bottom: 0.5rem;
  }
  
  button {
    background-color: #0e2037;
    color: #ffffff;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #1c3d5e;
  }
  
  @media (max-width: 768px) {
    .pricing-card {
      width: 100%;
    }
  }
  