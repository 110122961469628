.terms-of-service-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    line-height: 1.5;
  }
  
  .terms-of-service-container h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .terms-of-service-container h2 {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .terms-of-service-container ul {
    padding-left: 1.5rem;
  }
  