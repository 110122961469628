.postDetailss {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    /* height: 100vh; */
    width: 100vw;
    /* height:max-content; */
    /* background-color: #1e90ff; */
    /* background-size: cover; */
    /* background-color: #1e90ff; */
    background-image: linear-gradient(135deg, #1e5799, #207cca, #7db9e8);

    font-family: "Roboto", sans-serif;
    /* overflow: scroll; */
  }
  
  .projects-lists {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  
  .project-cards {
    background-color: #ffffff;
    padding: 2rem 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 70%;
    /* max-width: 900px; */
    transition: 0.3s;
  }
  
  .project-cards:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  h4 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #333;
  }
  
  .project-metas {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  span {
    font-size: 1.1rem;
    font-weight: 500;
    color: #555;
  }
  
  button {
    background-color: #1e90ff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-right: 5px;
  }
  
  button:hover {
    background-color: #0066cc;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    margin-bottom: 1rem;
    outline: none;
  }
  
  textarea {
    resize: none;
    height: 100px;
  }
  
  .bidContainers {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    overflow: scroll;
  }
  
  .bidBoxs {
    background-color: #f8f8f8;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 800px;
    margin-bottom: 1rem;
  }
  .bidBoxs p {
    font-size: 16px;
  }
  
  /* Responsive styles for various screen widths */
@media (max-width: 1000px) {
    .project-cards,
    .bidBoxs {
      width: 90%;
    }
  }
  
  @media (max-width: 750px) {
    .postDetailss {
      height: auto;
    }
  }
  
  @media (max-width: 550px) {
    .project-cards,
    .bidBoxs {
      width: 95%;
    }
  
    button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  
    input,
    textarea {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 450px) {
    .projects-lists{
        width: 100%;
    }
    .project-cards{
        width: 100%;
    }
    .project-cards,
    .bidBoxs {
      width: 95%;
    }
    h2 {
      font-size: 1.8rem;
    }
  
    h4 {
      font-size: 1.3rem;
    }
  
    p {
      font-size: 1.1rem;
    }
  
    span {
      font-size: 1rem;
    }
  
    button {
      padding: 6px 12px;
      font-size: 0.8rem;
    }
  
    input,
    textarea {
      font-size: 0.8rem;
    }
  }
  